@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.articleMedium {
  h3 {
    margin-bottom: 5px;
    font-weight: 600;
  }

  p {
    font-size: 13px;
    line-height: 18px;
    color: var(--font-dark);
  }

  // .postDesc { padding: 12px 0;}
}

.smallSize {
  & .postimg {
    width: 252px;
    height: 157px;
    border-radius: 12px;
  }
}

@media (max-width: 576px) {
  .articleMedium {
    p.desc {
      display: none;
    }
  }
}


@media (max-width: 991px) {
  .smallSize {
    & .postimg {
      aspect-ratio: initial;
      border-radius: 12px;
    }
  }
}